
import { Swiper, SwiperCore, SwiperSlide } from "swiper-vue2";
import { EffectFade, Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import { setAttributeToElements } from "~/utils";

SwiperCore.use([EffectFade, Pagination, Navigation]);

export default {
  name: "Slider",
  components: { Swiper, SwiperSlide },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => {},
    },
    blockIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      isMounted: false,
      pagination: false,
      navigation: false,
    };
  },
  computed: {
    mountedAndData() {
      return this.isMounted && this.data?.slides?.length;
    },
    swiperOptions() {
      return {
        slidesPerView: 1,
        spaceBetween: 0,
        roundLengths: true,
        effect: "fade",
        pagination: this.pagination,
        navigation: this.navigation,
      };
    },
  },
  watch: {
    mountedAndData: {
      handler(val) {
        if (!val) {
          return;
        }
        setAttributeToElements("a.hidden-ssr-link", "tabindex", "0");
      },
      immediate: true,
    },
  },
  mounted() {
    this.isMounted = true;
    this.pagination = {
      el: this.$refs.pagination,
      bulletClass: "main-slider-pagination-bullet",
      bulletActiveClass: "main-slider-dots-active",
      renderBullet: (index, className) => {
        return `<li class="${className}"><button><span class="sr-only">${this.$t(
          "ariaGoToSlide",
          { index: index + 1 }
        )}
        }</span></button></li>`;
      },
    };

    this.navigation = {
      prevEl: this.$refs.prev,
      nextEl: this.$refs.next,
    };
  },
  methods: {
    setSlickControlsOffset() {
      const offset = document
        .querySelector(".minimal-slider__feature")
        ?.getBoundingClientRect();
      const prevControl = document.querySelector(
        ".minimal-slider .main-slider-prev"
      );
      const nextControl = document.querySelector(
        ".minimal-slider .main-slider-next"
      );
      const dotsControl = document.querySelector(
        ".minimal-slider .main-slider-dots"
      );

      if (dotsControl && prevControl && nextControl) {
        prevControl.style.left = `${offset.left}px`;
        nextControl.style.right = `${offset.left}px`;
        dotsControl.style.right = `${offset.left}px`;
      }
    },
  },
};
